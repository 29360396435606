import React from "react";

interface Props {
  content: string;
}

export default function Hero({ content }: Props) {
  return (
    <div className="hero">
      <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
}
