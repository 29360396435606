import React, { useState } from "react";

import Hero from "./Header/Hero";
import Nav, { Props as NavProps } from "./Header/Nav";

type Props = NavProps & {
  hero?: string;
  className?: string;
};

export default function Header(props: Props) {
  const { hero } = props;
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => setShowMenu(!showMenu);

  const classNames = ["top-header"];
  if (showMenu) {
    classNames.push("show-menu");
  }
  if (props.className) {
    classNames.push(props.className);
  }

  return (
    <header className={classNames.join(" ")}>
      <div className="inner">
        <a href="/" className="logo">
          Stiftelsen UNI
        </a>
        <button
          aria-expanded={showMenu}
          aria-controls="top-nav"
          className="top-nav-toggle"
          onClick={toggleMenu}
        >
          {!showMenu ? "Meny" : "Lukk"}
        </button>
        <Nav {...props} />
        {hero && <Hero content={hero} />}
      </div>
    </header>
  );
}
