import React, { useState } from "react";

interface Props {
  url: string;
  query?: string;
}

export default function SearchForm(props: Props) {
  const { url } = props;
  const [query, setQuery] = useState(props.query || "");

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(evt.target.value);
  };

  return (
    <form action={url} method="GET" className="search-form">
      <input
        className={query ? "with-content" : ""}
        aria-label="Søk"
        name="q"
        type="text"
        value={query}
        placeholder="Søk"
        onChange={handleChange}
      />
    </form>
  );
}
