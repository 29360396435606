import React from "react";

import NavItem from "./NavItem";
import SearchForm from "./SearchForm";

export interface Props {
  currentRoot?: number;
  pages: NavPage[];
  searchUrl: string;
  searchQuery: string;
}

export default function Nav({
  currentRoot,
  pages,
  searchUrl,
  searchQuery,
}: Props) {
  return (
    <nav id="top-nav" className="top-nav">
      <ul>
        {pages.map((p) => (
          <NavItem key={p.id} page={p} current={p.id == currentRoot} />
        ))}
      </ul>
      <SearchForm url={searchUrl} query={searchQuery} />
    </nav>
  );
}
